.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1;
}

.App-header {
  background-color: #222;
  /* height: 75px; */
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.map_wrapper {
  border: 3px solid gray;
  height: 70vh;
  width: 100%;
  position: relative;
}

pre {
  text-align: left;
  padding: 10px;
}

.subscription_query {
  text-align: left;
  margin-bottom: 10px;
}

.tracking_info {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 10px;
}

.request_block {
  border-right: 1px solid #eee;
}

.request_block .subscription_wrapper {
  width: 90%;
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .request_block {
    margin-bottom: 10px;
  }
}

.marker-container {
  position: absolute;
  width: 30px; /* or whatever your marker width is */
  height: 30px; /* or whatever your marker height is */
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-image {
  width: 100%;
  height: 100%;
  transform-origin: center;
}